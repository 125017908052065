import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import taglineImg from '../images/imageryre-imagined.svg';

const FooterStyles = styled.footer`
  color: var(--white);
  background-color: var(--grey-5);
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: 1000px) {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }

  h3 {
    text-transform: uppercase;
    font-weight: var(--w-medium);
    font-size: 18px;
    margin: 0;
  }

  ul {
    list-style: none;
    margin: 0 0 2rem;
    padding: 0;
  }

  a {
    color: var(--white);
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:visited {
      color: var(--white);
    }
    &:hover,
    &:active,
    &:focus {
      border-bottom-color: white;
    }
  }

  .wrapper--footer {
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .footer-tagline,
  .footer-icon {
    flex: 1;
    margin: 0 2rem 3rem 0;

    a {
      &:hover,
      &:active,
      &:focus {
        border-bottom-color: transparent;
      }
    }

    img {
      max-width: 260px;
      @media (min-width: 960px) {
        max-width: 280px;
      }
    }
  }

  .footer-info {
    flex: 0 1 400px;
    display: flex;
    flex-wrap: wrap;
  }

  .footer-contact {
    margin-right: 6rem;
    margin-bottom: 2rem;
  }
  .copyright {
    font-size: 13px;
    flex: 1 0 100%;
    margin: 0;
  }
`;

export default function Footer({ data }) {
  const { title, tagline, socials, phone, email } = data.settings;
  return (
    <FooterStyles>
      <div className="wrapper wrapper--footer">
        <div className="footer-tagline">
          <Link to="/">
            <img src={taglineImg} alt={tagline} />
          </Link>
        </div>

        <div className="footer-info">
          <ul className="footer-contact">
            <li>
              <h3>Get In Touch</h3>
            </li>
            <li>
              E | <a href={`mailto:${email}`}>{email}</a>
            </li>
            <li>
              P | <a href={`tel:${phone}`}>{phone}</a>
            </li>
          </ul>

          <ul className="footer-social">
            <li>
              <h3>Socials</h3>
            </li>
            {socials.map((social) => (
              <li key={social._key}>
                <a
                  href={social.socialUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {social.socialNetwork}
                </a>
              </li>
            ))}
          </ul>
          <p className="copyright">
            All images and content © {new Date().getFullYear()} {title}
          </p>
        </div>
      </div>
    </FooterStyles>
  );
}

Footer.propTypes = {
  title: PropTypes.string,
};

Footer.defaultProps = {
  title: `Richard Jupe`,
};
