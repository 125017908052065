import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Nav from './Nav';

import logo from '../images/richardjupe-logo.svg';

const HeaderStyles = styled.header`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: 600px) {
    padding-bottom: 4rem;
  }

  svg {
    font-size: 26px;
  }
`;
const BrandStyles = styled.div`
  max-width: 200px;
  @media (min-width: 600px) {
    min-width: 260px;
  }
`;

const Header = ({ siteTitle }) => (
  <HeaderStyles className="wrapper">
    <BrandStyles>
      <Link to="/">
        <img src={logo} alt={siteTitle} />
        {/* {siteTitle} */}
      </Link>
    </BrandStyles>
    <Nav />
  </HeaderStyles>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
