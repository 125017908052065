import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GoX } from 'react-icons/go';

const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--charcoal);
  z-index: 9999;
  padding-top: 2rem;
  /* opacity: 0.5; */
`;

const MenuStyles = styled.ul`
  list-style: none;
  padding: 4rem 0 0 0;
  margin: 0;
  line-height: 1.3;
  li {
    margin-bottom: 0.3rem;
  }
  a {
    color: var(--white);
    font-weight: 900;
    font-size: 31px;
    text-decoration: none;
    text-transform: uppercase;
    transition: var(--tran);

    &:visited {
      color: var(--white);
    }
    &:hover,
    &:active,
    &:focus {
      color: var(--grey-4);
    }
  }

  .standard-link {
    a {
      color: var(--grey-3);
      &:visited {
        color: var(--grey-3);
      }
      &:hover,
      &:active,
      &:focus {
        color: var(--grey-1);
      }
    }
  }
`;

const MenuBtn = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  transition: var(--tran);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: var(--grey-3);
  }
`;
const CloseBtn = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  color: var(--white);
  float: right;
  transition: var(--tran);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: var(--grey-3);
  }
`;

export default function Nav() {
  const [isToggled, setToggle] = useState(false);

  const toggleNav = () => setToggle((prevState) => !prevState);
  return (
    <nav>
      <MenuBtn onClick={toggleNav}>
        <GiHamburgerMenu />
      </MenuBtn>

      {isToggled && (
        <MenuWrapper>
          <div className="wrapper">
            <CloseBtn onClick={toggleNav}>
              <GoX />
            </CloseBtn>
            <MenuStyles>
              <li>
                <Link to="/gallery/portraits/" onClick={toggleNav}>
                  Portraits
                </Link>
              </li>
              <li>
                <Link to="/gallery/lifestyle/" onClick={toggleNav}>
                  Lifestyle
                </Link>
              </li>
              <li>
                <Link to="/gallery/news/" onClick={toggleNav}>
                  News
                </Link>
              </li>

              <li className="standard-link" style={{ marginTop: '20px' }}>
                <Link to="/" onClick={toggleNav}>
                  Home
                </Link>
              </li>
              <li className="standard-link">
                <Link to="/about/" onClick={toggleNav}>
                  About
                </Link>
              </li>
              {/* <li className="standard-link">
                <Link to="/journal/" onClick={toggleNav}>
                  Journal
                </Link>
              </li> */}
            </MenuStyles>
          </div>
        </MenuWrapper>
      )}
    </nav>
  );
}
