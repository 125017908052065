import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  html {
    font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }

  body {
    font-size: 16px;
    color: var(--black);
    line-height: 1.6;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: 400;
  }

  // Typography

  .page-title {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: var(--w-medium);
      margin: 0;
      line-height: 1.2;
  }

  .main-content {
    h2 {
      font-size: 18px;
      font-weight: var(--w-medium);
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
    h3 {
      font-size: 16px;
      font-weight: var(--w-medium);
      margin-top: 1.5rem;
      margin-bottom: 0;
      /* text-transform: uppercase; */
      color: var(--grey-4);
    }

    p {
      margin: 0 0 1rem;
      color: var(--grey-4);
    }

    strong {
      font-weight: var(--w-medium);
      color: var(--black);
    }

    blockquote {
      font-size: 26px;
      line-height: 1.4;
      /* margin-left: 0;
      margin-right: 0; */
    }

    // Links
    a {
      color: var(--black);
      transition: var(--tran);

      &:visited {
        color: var(--black);
      }
      &:hover,
      &:active,
      &:focus {
        color: var(--grey-4);
      }
    }

    // cancel margin top on headings for main content
    *:first-child {
      margin-top: 0;
    }
  }

`;

export default Typography;
