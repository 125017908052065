module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T83795N","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"NEW_PAGE_EVENT"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"richard-jupe","short_name":"richardjupe","start_url":"/","background_color":"#333","theme_color":"#333","display":"minimal-ui","icon":"src/images/richardjupe-site-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2f91b9c40ff6f87698c9af96f16f2417"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
