import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';

import 'normalize.css';
import '../styles/fonts.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteSettingsQuery {
      settings: sanitySiteSettings {
        title
        tagline
        phone
        email
        socials {
          socialNetwork
          socialUrl
          _key
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles />
      <Typography />
      <Header siteTitle={data.settings.title} />
      <main className="wrapper">{children}</main>
      <Footer data={data} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
