import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
:root {
    --black: #000;
    --white: #fff;
    --charcoal: #333;
    --grey: #C4C4C4;

    --grey-1: #F4F4F4;
    --grey-2: #E1E1E1;
    --grey-3: #959494;
    --grey-4: #5E5E5E;
    --grey-5: #333333;

    --bg-color: var(--white);

    --spacer: 1rem;
    --page-width: 1165px;

    --w-regular: 400;
    --w-medium: 500;
    --w-black: 900;

    --tran: all 0.3s ease-in-out;
  }
  html {
    box-sizing: border-box;
    background-color: var(--bg-color);
  }

  *,
  *:before,
  *:after {
    /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
    box-sizing: inherit;
  }

  body {
  }

  hr {
    background-color: #ccc;
    border: 0;
    height: 1px;
    margin-bottom: var(---spacer) * 1.5;
  }
  img {
    height: auto; /* Make sure images are scaled correctly. */
    max-width: 100%; /* Adhere to container width. */
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle;
  }

  figure {
    margin: var(--spacer) 0; /* Extra wide images within figure tags don't overflow the content area. */
  }

  // Lists
  ul,
  ol {
    margin: 0 0 var(--spacer) * 1.5 0;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li > ul,
  li > ol {
    margin-bottom: 0;
    margin-left: var(--spacer) * 1.5;
  }

  // Reset lists in Nav
  nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  // Tables
  table {
    margin: 0 0 var(--spacer) * 1.5;
    width: 100%;
  }

  // Layout
  .wrapper {
    max-width: var(--page-width);
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: 500px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  // Gallery Layout
  .gallery-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
    margin-left: -16px; /* gutter size offset */
    @media (min-width: 600px) {
      margin-left: -30px; /* gutter size offset */
    }
  }
  .gallery-grid_column {
    background-clip: padding-box;
    padding-left: 16px; /* gutter size */
    @media (min-width: 600px) {
      padding-left: 30px; /* gutter size */
    }
  }

  /* Style your items */
  .gallery-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: var(--white);
    margin-bottom: 16px;
    @media (min-width: 600px) {
      margin-bottom: 30px;
    }
  }
`;

export default GlobalStyles;
